<template>
  <form-wizard
    color="#B1D06C"
    :title="null"
    :subtitle="null"
    shape="square"
    finish-button-text="Submit"
    @on-complete="formSubmitted"
    back-button-text="Previous"
    class="mb-3"
  >
    <!-- Search details tab -->
    <tab-content title="Details">
      <b-form @submit.prevent="formSubmitted" @change="formChanged()">
        <b-row>
          <b-col md="6">
            <b-form-group label="Template Name" label-for="mc-Template-name">
              <b-form-input
                id="mc-Template-name"
                placeholder="Template Name"
                v-model="details.name"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Template Code" label-for="mc-Template-Code">
              <b-form-input
                id="mc-Template-Code"
                placeholder="Template Code"
                v-model="details.code"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" class="mb-2">
            <b-form-group label="Subject" label-for="textarea-default">
              <b-form-input
                id="textarea-default"
                type="text"
                placeholder="Subject"
                v-model="details.description"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mb-2">
            <span>Template Content</span>
         
            <quill-editor v-model="details.letterDescription" :options="editorOption">
                <div id="toolbar" slot="toolbar">
                  <button class="ql-bold">Bold</button>
                  <button class="ql-italic">Italic</button>
                  <button class="ql-underline">Underline</button>
                  <button class="ql-strike">Strike</button>
                  <button class="ql-blockquote">Quote</button>
                  <button class="ql-list" value="ordered"></button>
                  <button class="ql-list" value="bullet"></button>
                  <button class="ql-indent" value="-1"></button>
                  <button class="ql-indent" value="+1"></button>
                  <button class="ql-script" value="sub"></button>
                  <button class="ql-script" value="super"></button>
                  <button class="ql-background"></button>
                  <select class="ql-font">
                    <option selected="selected"></option>
                    <option value="serif">serif</option>
                    <option value="monospace">monospace</option>
                  </select>
                  <select class="ql-header">
                    <option value="1">Heading 1</option>
                    <option value="2">Heading 2</option>
                    <option value="3">Heading 3</option>
                    <option value="4">Heading 4</option>
                    <option value="5">Heading 5</option>
                    <option value="6">Heading 6</option>
                    <option selected></option>
                  </select>
                  <select class="ql-align">
                    <option selected></option>
                    <option value="center"></option>
                    <option value="right"></option>
                    <option value="justify"></option>
                  </select>
                </div>
              </quill-editor>
          </b-col>
          <!-- <b-col cols="12">
            <label for="textarea-default">Service File (Optional)</label>
            <b-form-file
              v-model="details.file"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop picture here..."
            />

            <b-card-text class="my-2">
              Selected file: <strong>{{ details.file ? details.file.name : "" }}</strong>
            </b-card-text>
          </b-col> -->
        </b-row>
      </b-form>
    </tab-content>
    <tab-content title="Pricing">
      <b-form @submit.prevent @change="formChanged()">
        <b-row>          
          <b-col cols="12">
            <b-form-group label="Total Cost (Rs)" label-for="cost" label-cols-md="4">
              <b-form-input
                id="cost"
                type="number"                
                placeholder="Rs 6000"
                v-model="details.purchasePrice"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Percentage Mark Up (%)"
              label-for="markup"
              label-cols-md="4"
            >
            <b-form-input
              id="markup"
              type="number"
              placeholder="Ex : 20%"
              v-model="details.markUpPercentage"
            />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Sales VAT (%) (Optional)"
              label-for="tax"
              label-cols-md="4"
            >
              <b-form-input
                id="tax"
                type="number"
                placeholder="Ex : 15%"
                v-model="details.saleTaxPercentage"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Calculate Selling Price (Rs)"
              label-for="price"
              label-cols-md="4"
            >
            <b-form-input
              id="price"
              type="number"
              placeholder="Rs 8280"
              v-model="details.salePrice"
            />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </tab-content>
    <tab-content title="Finish">
      <b-container class="d-flex justify-content-center last_step flex-column">
        <img src="./check-mark.svg" style="height: 200px" alt="" />
        <h2 class="text-center mt-3 text-primary">Submit and Confirm</h2>
        <div class="d-flex justify-content-center">
          <b-spinner large v-if="loading" class="mx-2" />
          <span v-if="loading">Loading...</span>
        </div>
      </b-container>
    </tab-content>
  </form-wizard>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BForm,
  BButton,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BFormCheckbox,
  BFormFile,
  BContainer,
  BCard,
  BCardText,
  BSpinner
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { FormWizard, TabContent } from "vue-form-wizard";
import { mapActions, mapGetters } from "vuex";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { quillEditor } from "vue-quill-editor";
export default {
  components: {
    BFormSelect,
    BFormFile,
    BFormGroup,
    BForm,
    BFormTextarea,
    BFormInput,
    FormWizard,
    BContainer,
    BButton,
    BFormCheckbox,
    BCard,
    TabContent,
    BRow,
    BCol,
    ToastificationContent,
    BCardText,
    BSpinner,quillEditor
  },
  methods: {    
    ...mapActions(
      "productModule", 
      [
        "addProductAction"
      ]
    ),
    formChanged() {            
      let markupAmount = parseFloat(this.details.purchasePrice ?? 0) * (parseFloat(this.details.markUpPercentage ?? 0) / 100)
      let priceBeforeTax = parseFloat(this.details.purchasePrice ?? 0) + markupAmount;
      let taxAmount = priceBeforeTax * (parseFloat(this.details.saleTaxPercentage ?? 0) / 100);
      this.details.salePrice = (priceBeforeTax + taxAmount).toFixed(2);      
    },    
    formSubmitted() {
      this.addProductAction({ product: this.details, file: this.details.file })
        .then(() => {
          this.success();          
        })
        .catch((ex) => {
          this.error(ex.response.data);          
        });
    },
    success() {
      this.$swal({
          title: 'Service Templates!',
          text: 'You have successfully added the template!',
          icon: 'success',
          customClass: {
              confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
      }).then(result => {
          if (result.value) {
              this.$router.push({ name: 'templates-list' });
          }
      })
    },
    error(data) {
      let msg = null;
      if(data)
          msg = this.getErrorString(data);
      this.$swal({
          title: 'Error!',
          //text: msg ?? ' Failed!',
          html: msg ?? " Failed!",
          icon: 'error',
          customClass: {
              confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
      })
    },
    getErrorString(data) {
      let msg = null;      
      if(data.errors) { 
          let errors = data.errors;            
          msg = "<div>";
          msg += "<p>Cannot post your request due to the following errors:</p>"
          for (var prop in errors) {
              if (Object.prototype.hasOwnProperty.call(errors, prop) && prop !== "request") {
                  msg += "<p>";
                  msg += prop.replace("$.", "");
                  msg += ": ";
                  msg += errors[prop].toString().replace("$.", "");
                  msg += "</p>"
              }
          }
          msg += "</div>";          
      }
      else if(data.message) {        
          msg = "<div>";
          msg += "<p>Cannot post your request due to the following errors:</p>"
          msg += data.message;
          msg += "</div>";
      }
      return msg;
    }
  },
  data() {
    return {      
      details: {
        type: 4,
        name: null,
        code: null,
        barcode: this.code,
        description: null,
        letterDescription: null,
        salePrice: null,
        purchasePrice: null,        
        markUpPercentage: null,
        saleTaxPercentage: null,
        file: null,
        lowStock: 1
      },
      editorOption: {
        modules: {
          toolbar: "#toolbar",
        },
      },
    };
  },
  computed: {
    ...mapGetters("productModule", {                        
        loading: "loading"
    })
  }
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/quill.scss";
</style>

<style scoped>
label {
  font-size: 1.714rem !important;
  font-weight: 500;
  margin-bottom: 0;
}
</style>
